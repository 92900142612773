import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Button, ButtonGroup, Dropdown, FieldCheckbox, FieldSelect, Icon, RadioGroup} from '@bitstillery/common/components'
import {format_date} from '@bitstillery/common/lib/format'
import {$t, api, logger, store} from '@bitstillery/common/app'

import {$s} from '@/app'
import {ExportPortalPriceListRequest} from '@/factserver_api/fact2server_api'
import {collection} from '@/components/pages/offers/offer_list'

export class PricelistDownload extends MithrilTsxComponent<any> {

    data = (() => {
        const formatting:any = {
            alcohol: {
                options: [
                    ['percentage', 'Percentage'],
                    ['decimal', 'Decimal (0.x)'],
                ],
            },
            size: {
                options: [
                    {value: 'l', label: 'l - Liters'},
                    {value: 'cl', label: 'cl - Centiliters'},
                    {value: 'ml', label: 'ml - Milliliters'},
                ],
            },
        }
        collection.state.selection.all = true // This way the select all products is already selected

        return proxy({
            _selection: () => {
                if (collection.state.selection.all) {
                    return collection.state.total - collection.state.selection.ids.length
                } else {
                    return collection.state.selection.ids.length
                }
            },
            formatting,
        })
    })()

    async download() {
        const vendor = process.env.MSI_TITLE.replaceAll(' ', '-').toLowerCase()
        const file_name = `${vendor}-${$t('filters.pricelist.filename').replaceAll(' ', '-')}-${format_date(new Date)}`
        logger.info(`download ${file_name}`)
        // Store current export settings.
        store.save()
        $s.offers.export.loading = true

        const price_list_data: ExportPortalPriceListRequest = {
            include_case_artkeys: [],
            exclude_case_artkeys: [],
            search_terms: collection.state.query.search_terms,
            offer_hash: collection.state.query.offer_hash,
            availabilities: collection.state.query.availabilities,
            volume: collection.state.query.volume,
            alcohol_percentage: collection.state.query.alcohol_percentage,
            offer_item_type: collection.state.query.offer_item_type,
            packaging: collection.state.query.packaging,
            refill_status: collection.state.query.refill_status,
            category_artkeys: collection.state.query.category_artkeys,
            formatting: {
                alcohol: $s.offers.export.formatting.alcohol.selection,
                volume: $s.offers.export.formatting.size.selection,
            },
        }

        if (collection.state.selection.all) {
            price_list_data.exclude_case_artkeys = collection.state.items
                .filter((item) => collection.state.selection.ids.includes(item.artkey))
                .map((item) => item.case_artkey)

        } else if (collection.state.selection.ids.length) {
            price_list_data.include_case_artkeys = collection.state.items
                .filter((item) => collection.state.selection.ids.includes(item.artkey))
                .map((item) => item.case_artkey)
        } else {
            price_list_data.include_case_artkeys = []
        }
        await api.download('portal/offer-items/price-list', file_name, price_list_data)
        $s.offers.export.loading = false
        $s.offers.export.active = false
    }

    view(_vn:m.Vnode<any>) {
        return <Dropdown
            className="c-pricelist-download collection-header-action"
            clickOutside={true}
            model={$s.offers.export}
            trigger="click"
        >
            <Button
                active={$s.offers.export.active}
                onclick={() => {
                    $s.offers.export.active = true
                }}
                icon={'download'}
                tip={$t('filters.pricelist.download_tip')}
                type="info"
                variant="menu"
            />

            <div className="dropdown-body">
                <div class="dropdown-body-header">
                    <Icon
                        className={classes({active: $s.offers.export.active})}
                        name={'download'}
                        type="unset"
                    />
                    <span className='title'>{$t('filters.pricelist.download')}</span>
                </div>
                <div class="options">
                    <FieldCheckbox
                        className="select-toggle"
                        help={$t('filters.pricelist.products_selected', {
                            count: this.data._selection,
                            total: collection.state.total,
                        })}
                        model={[collection.state.selection, 'all']}
                        label={$t('filters.pricelist.select_all')}

                    />
                    <RadioGroup
                        help={$t('filters.pricelist.alcohol_format_help')}
                        label={$t('filters.pricelist.alcohol_format')}
                        model={[$s.offers.export.formatting.alcohol, 'selection']}
                        options={this.data.formatting.alcohol.options}
                    />
                    <FieldSelect
                        help={$t('filters.pricelist.volume_format_help')}
                        label={$t('filters.pricelist.volume_format')}
                        model={[$s.offers.export.formatting.size, 'selection']}
                        options={this.data.formatting.size.options}
                    />

                    <ButtonGroup>
                        <Button
                            className="btn-download a-btn-download"
                            icon="cancel"
                            onclick={(e) => {
                                e.stopPropagation()
                                $s.offers.export.active = false
                                $s.offers.export.toggle = false
                            }}
                            text={$t('filters.pricelist.cancel_download')}
                            tip={() => {
                                if ($s.offers.export.loading) return $t('filters.pricelist.download_tip_busy')
                                return $t('filters.pricelist.download_tip')
                            }}
                        />
                        <Button
                            className="btn-download a-btn-download"
                            disabled={$s.offers.export.loading || !this.data._selection}
                            icon="download"
                            loading={$s.offers.export.loading}
                            onclick={async() => {
                                await this.download()
                            }}
                            text={$t('filters.pricelist.download')}
                            tip={() => {
                                if ($s.offers.export.loading) return $t('filters.pricelist.download_tip_busy')
                                return $t('filters.pricelist.download_tip')
                            }}
                            type="info"
                        />
                    </ButtonGroup>
                </div>
            </div>
        </Dropdown>
    }
}
